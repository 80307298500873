<template>
  <div class="view-card --default">
    <ul class="country-list">
      <li class="country-list-item" v-for="(country, index) in defaultCountries" :key="`c-${index}`" @click="setCountry(country)">
        <span class="country-list-item-flag" :style="`--flag:url(${country.flag})`"></span>
        <span class="country-list-item-name">{{ country.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["countries"],
  data: function() {
    return {
      defaultCountries: {},
    };
  },
  methods: {
    getDefaults: function() {
      const word = ["united states of america"];
      this.defaultCountries = this.countries.filter((item) => {
        return word.includes(item.name.toLowerCase());
      });
    },
    setCountry: function(country) {
      this.$emit("country", country);
    },
  },
  mounted: function() {
    this.getDefaults();
  },
};
</script>
